<template>
  <div>
    <v-card flat>
      <v-card-text
        :class="!$vuetify.breakpoint.mobile ? '' : 'pa-0'"
        :style="{
          height: !$vuetify.breakpoint.mobile ? '90vh' : 'calc(100dvh - 56px - 96px)',
          'overflow-y': 'auto',
        }"
      >
        <v-row>
          <v-col cols="12" sm="6" md="3" v-if="!$vuetify.breakpoint.mobile">
            <v-card height="100%" flat style="background-color: var(--v-greyBase-base) !important; border-radius: 20px;" class="pa-2">
            <v-menu bottom offset-y>
              <template v-slot:activator="{ on, attrs }">
                <v-chip
                  v-on="on"
                  v-bind="attrs"
                  color="blue-grey"
                  class="mb-3 mr-2"
                >
                  {{ quickSelectDates.find(x => x.value == params.selectedDate).text }}
                  <v-icon small right>expand_more</v-icon>
                </v-chip>     
              </template>
              <v-card style="background-color: var(--v-greyBase-base) !important;">
                <v-list class="pa-0" dense >
                  <v-list-item class="px-2" @click="selectDate(date.value)" v-for="(date, index) in quickSelectDates.filter(x => x.value != params.selectedDate)" :key="index">
                    <v-list-item-title>
                      {{ date.text }}
                    </v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-card>
            </v-menu>
            <v-menu
              ref="menu"
              v-model="dateMenu"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-chip
                  color="blue-grey"
                  v-bind="attrs"
                  v-on="on"
                  class="mb-3"
                  ><v-icon small left >date_range</v-icon>{{ dateText }}
                  <v-icon  small right>expand_more</v-icon></v-chip
                >
              </template>
              <v-date-picker
                v-model="params.dates"
                range
                color="greyBase"
                show-week
                scrollable
              >
                <v-spacer></v-spacer>
                <v-btn text color="primary"> Cancel </v-btn>
                <v-btn text color="primary" @click="setDateFilter">
                  OK
                </v-btn>
              </v-date-picker>
            </v-menu>
            <v-chip
              class="mr-3"
              :key="dateKey"
              :color="params.dateType == 'ETD' ? 'teal' : 'white'"
              outlined
              @click="changeDateType()"
            >
              <v-icon small left>event</v-icon> Date Type:
              {{ params.dateType.toUpperCase() }}
            </v-chip>
            <v-chip :outlined="!params.showAllFuture" :color="params.showAllFuture ? 'success' : 'white'" @click="params.showAllFuture = !params.showAllFuture, saveParams, getShipments()">
              <v-icon small left >{{ params.showAllFuture ? 'check_box' : 'check_box_outline_blank' }}</v-icon>
              Show All Future Items
            </v-chip>
            <!-- <v-checkbox
                hide-details
                dense
                class="ma-0"
                label="Show all future items"
                v-model="params.showAllFuture"
                @change="saveParams, getShipments()"
              ></v-checkbox> -->
            </v-card>
          </v-col>
          <v-col cols="12" sm="6" md="2" v-if="!$vuetify.breakpoint.mobile" class="px-2 d-flex align-end" style="flex-direction: column;">
            <v-chip
                style="height: 40px; width: 100%;"
                outlined
                small
                class="pr-1 mb-2 text-center"
              >
                <v-icon small>search</v-icon>
                <v-text-field
                  placeholder="Search"
                  class="mb-1"
                  hide-details
                  rounded
                  clearable
                  dense
                  v-model="search"
                ></v-text-field>
              </v-chip>
              <v-autocomplete
                dense
                style="width: 100%; border-radius: 12px;"
                outlined
                hide-details
                :items="uniqueConsignees"
                v-model="filteredConsigneeId"
                item-text="name"
                item-value="id"
                label="Filtered Consignee"
                clearable
              >
                <template v-slot:label>
                  <span style="color: hsla(0,0%,100%,.3); font-size: 14px;" class="ml-1">Filtered Consignee</span>
                </template>
                <template v-slot:prepend-inner>
                  <v-icon small class="mt-1 mr-4">filter_alt</v-icon>
                </template>
            </v-autocomplete>
          </v-col>
          <v-col cols="12" sm="8" md="7" :class="!$vuetify.breakpoint.mobile ? '' : 'mt-3'" >
            <v-row no-gutters style="height: 100%;" >
              <v-col cols="12" sm="12" md="4" class="px-1 mb-2">
                <v-card outlined flat style="border-radius: 20px; height: 100%;">
                  <v-card-text class="px-0 py-0" style="height: 100%;">
                    <v-row no-gutters style="height: 100%;">
                      <v-col cols="2" class="d-flex justify-center align-center">
                        <v-icon color="blue">{{
                          getStatusIcon("Arrived")
                        }}</v-icon>
                      </v-col>
                      <v-col cols="4" class="d-flex justify-center align-center">
                        <v-list-item-title style="font-size: 14px; white-space: pre-line; text-align: center;">
                          Arrived
                        </v-list-item-title>
                      </v-col>
                      <v-col cols="1" ></v-col>
                      <v-col cols="5" class="d-flex justify-center" style="flex-direction: column;" >
                        <span>
                          <v-icon small color="grey" class="mr-1">widgets</v-icon>
                          {{ filteredContainers("Arrived") }} containers
                        </span>
                        <span>
                          <v-icon small color="grey" class="mr-1">list_alt</v-icon>
                          {{ filteredPallets("Arrived") }} Pallets
                        </span>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-col>
              <v-col cols="12" sm="12" md="4" class="px-1 mb-2">
                <v-card outlined flat style="border-radius: 20px; height: 100%;">
                  <v-card-text class="px-0 py-0" style="height: 100%;">
                    <v-row no-gutters style="height: 100%;">
                      <v-col cols="2" class="d-flex justify-center align-center">
                        <v-icon color="blue">{{
                          getStatusIcon("In Transit")
                        }}</v-icon>
                      </v-col>
                      <v-col cols="4" class="d-flex justify-center align-center">
                        <v-list-item-title style="font-size: 14px; white-space: pre-line; text-align: center;">
                          In Transit
                        </v-list-item-title>
                      </v-col>
                      <v-col cols="1" ></v-col>
                      <v-col cols="5" class="d-flex justify-center" style="flex-direction: column;" >
                        <span>
                          <v-icon small color="grey" class="mr-1">widgets</v-icon>
                          {{ filteredContainers("In Transit") }} containers
                        </span>
                        <span>
                          <v-icon small color="grey" class="mr-1">list_alt</v-icon>
                          {{ filteredPallets("In Transit") }} Pallets
                        </span>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-col>
              <v-col cols="12" sm="12" md="4" class="px-1 mb-2">
                <v-card outlined flat style="border-radius: 20px; height: 100%;">
                  <v-card-text class="px-0 py-0" style="height: 100%;">
                    <v-row no-gutters style="height: 100%;">
                      <v-col cols="2" class="d-flex justify-center align-center">
                        <v-icon color="blue">{{
                          getStatusIcon("Awaiting Departure")
                        }}</v-icon>
                      </v-col>
                      <v-col cols="4" class="d-flex justify-center align-center">
                        <v-list-item-title style="font-size: 14px; white-space: pre-line; text-align: center;">
                          Awaiting Departure
                        </v-list-item-title>
                      </v-col>
                      <v-col cols="1" ></v-col>
                      <v-col cols="5" class="d-flex justify-center" style="flex-direction: column;" >
                        <span>
                          <v-icon small color="grey" class="mr-1">widgets</v-icon>
                          {{ filteredContainers("Awaiting Departure") }} containers
                        </span>
                        <span>
                          <v-icon small color="grey" class="mr-1">list_alt</v-icon>
                          {{ filteredPallets("Awaiting Departure") }} Pallets
                        </span>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
          </v-col>

          <v-col cols="12" class="mt-0 pt-0" v-if="!$vuetify.breakpoint.mobile">
            <v-data-table
              id="shipment-table"
              ref="table"
              :key="filterKey"
              disable-sort
              disable-pagination
              dense
              hide-default-footer
              hide-default-header
              :loading="loading"
              class="mt-0 pt-0"
              :group-by="'position'"
              @click:row="viewShipment"
              :items="filteredResults"
              height="70vh"
              :headers="shipmentHeaders"
              style="font-size: 11px; cursor: pointer"
              fixed-header
            >
              <template #header="{ props: { headers } }">
                <thead class="v-data-table-header">
                  <tr>
                    <th
                      scope="col"
                      v-for="(head, index) in headers"
                      :key="index"
                      style="
                        font-size: 12px;
                        color: grey;
                        border-bottom: 1px solid grey;
                        padding-top: 10px;
                      "
                    >
                      <div class="text-center">
                        {{ head.text
                        }}<v-badge
                          v-if="head.filterable"
                          style="z-index: 1000"
                          :content="
                            filters[head.value]
                              ? filters[head.value].length
                              : false
                          "
                          :value="
                            filters[head.value] &&
                            filters[head.value].length > 0
                          "
                          color="primary"
                          offset-y="10"
                        >
                          <v-menu offset-y left :close-on-content-click="false">
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn
                                v-bind="attrs"
                                v-on="on"
                                small
                                class="ml-1"
                                icon
                                ><v-icon small>filter_alt</v-icon></v-btn
                              >
                            </template>
                            <v-list dense>
                              <v-list-item
                                v-for="(item, index) in headerOptions(
                                  head.value
                                )"
                                :key="index"
                              >
                                <v-list-item-action class="pr-0">
                                  <v-checkbox
                                    v-model="filters[head.value]"
                                    :value="item.value"
                                    hide-details
                                    dense
                                    @change="calculateFilteredResults()"
                                  ></v-checkbox>
                                </v-list-item-action>
                                <v-list-item-content>
                                  <v-list-item-title>
                                    {{ item.name }}
                                  </v-list-item-title>
                                </v-list-item-content>
                              </v-list-item>
                            </v-list>
                          </v-menu>
                        </v-badge>
                      </div>
                    </th>
                  </tr>
                </thead>
              </template>

              <template v-slot:[`group.header`]="{ items, isOpen, toggle }">
                <th colspan="3">
                  <v-icon @click="toggle" small class="mr-1"
                    >{{ isOpen ? "remove" : "add" }}
                  </v-icon>
                  <v-chip small class="mx-1">{{ items.length }}</v-chip>
                  <span>{{ items[0].vesselVoyageName }}</span>
                </th>
                <!-- <th colspan="2" class="text-left" style="color: grey">
                  <v-chip small outlined style="border: none">
                    <v-chip x-small class="mx-1" left color="blue-grey">
                      {{
                        items
                          .map((x) => x.noContainers)
                          .reduce((a, b) => a + b, 0)
                      }}
                    </v-chip>

                    Containers -
                    <v-chip x-small class="mx-1" color="blue-grey" size="26">
                      {{
                        items.map((x) => x.noPallets).reduce((a, b) => a + b, 0)
                      }}
                    </v-chip>

                    Pallets -
                    <v-chip x-small class="mx-1" color="blue-grey" size="26">
                      {{
                        items.map((x) => x.noCartons).reduce((a, b) => a + b, 0)
                      }}
                    </v-chip>

                    Units 
                  </v-chip>
                </th>
                -->
                <th colspan="2" class="text-left" style="color: grey">
                  <v-chip small outlined style="border: none">
                    <v-chip x-small class="mx-1" left color="blue-grey">
                      {{
                        items
                          .map((x) => x.noContainers)
                          .reduce((a, b) => a + b, 0)
                      }}
                    </v-chip>

                    Containers
                    </v-chip>
</th>
<th colspan="2" class="text-left" style="color: grey">
  <v-chip small outlined style="border: none">
    <v-chip x-small class="mx-1" color="blue-grey" size="26">
                      {{
                        items.map((x) => x.noPallets).reduce((a, b) => a + b, 0)
                      }}
                    </v-chip>

                    Pallets
                    </v-chip>
</th>

<th colspan="2" class="text-left" style="color: grey">
  <v-chip small outlined style="border: none">
    <v-chip x-small class="mx-1" color="blue-grey" size="26">
                      {{
                        items.map((x) => x.noCartons).reduce((a, b) => a + b, 0)
                      }}
                    </v-chip>

                    Units
                    </v-chip>
</th>


<th colspan="2" class="text-left" style="color: grey">
  <v-chip small outlined style="border: none">
    <v-chip
                      class="mx-1"
                      :color="
                        items
                          .map((x) => x.documents.complete)
                          .reduce((a, b) => a + b, 0) ==
                        items
                          .map((x) => x.documents.total)
                          .reduce((a, b) => a + b, 0)
                          ? 'green'
                          : 'blue'
                      "
                      x-small
                    >
                      {{
                        items
                          .map((x) => x.documents.complete)
                          .reduce((a, b) => a + b, 0)
                      }}
                      /
                      {{
                        items
                          .map((x) => x.documents.total)
                          .reduce((a, b) => a + b, 0)
                      }}
                    </v-chip>

                    Documents
                    </v-chip>
</th>

                <th colspan="3" class="text-left"></th>
              </template>
              <template v-slot:[`item.status`]="{ item }">
                <v-chip :color="getStatusColor(item.status)" outlined small>
                  <v-icon left small class="mr-1">{{
                    getStatusIcon(item.status)
                  }}</v-icon>
                  {{ item.status }}</v-chip
                >
              </template>

              <template v-slot:[`item.arrivalWeek`]="{ item }">
                <span v-if="item.eta">{{ formatWeek(item.eta) }}</span>
              </template>

              <template v-slot:[`item.portOfLoadValue`]="{ item }">
                <v-tooltip top v-if="item.portOfLoadValue">
                  <template v-slot:activator="{ on }">
                    <v-chip pill outlined v-on="on" small>
                      <v-avatar size="24" left>
                        <v-img
                          contain
                          :src="`https://cdn.loglive.io/flags/4x3/${item.portOfLoadValue
                            .substring(0, 2)
                            .toLowerCase()}.svg`"
                        ></v-img>
                      </v-avatar>
                      {{ item.portOfLoadValue }}
                    </v-chip>
                  </template>
                  <span style="font-size: 12px">{{ item.portOfLoadCity }}</span>
                </v-tooltip>
              </template>
              <template v-slot:[`item.finalDestinationValue`]="{ item }">
                <v-tooltip top v-if="item.finalDestinationValue">
                  <template v-slot:activator="{ on }">
                    <v-chip pill outlined v-on="on" small>
                      <v-avatar size="24" left>
                        <v-img
                          contain
                          :src="`https://cdn.loglive.io/flags/4x3/${item.finalDestinationValue
                            .substring(0, 2)
                            .toLowerCase()}.svg`"
                        ></v-img>
                      </v-avatar>
                      {{ item.finalDestinationValue }}
                    </v-chip>
                  </template>
                  <span style="font-size: 12px">{{
                    item.finalDestinationCity
                  }}</span>
                </v-tooltip>
              </template>
              <template v-slot:[`item.products`]="{ item }">
                <ul class="ml-0 pl-0">
                  <li
                    v-for="product in item.products.filter(x=>x.product)"
                    :key="product.id"
                    style="font-size: 11px"
                  >
                    {{
                      product.product
                        ? $Format.capitalizeFirstLetter(product.product.name)
                        : ""
                    }}
                  </li>
                </ul>
              </template>
              <template v-slot:[`item.documents`]="{ item }">
                <v-chip
                  v-if="
                    item.documents.complete > 0 || item.documents.incomplete > 0
                  "
                  x-small
                  :color="
                    item.documents.complete == item.documents.total
                      ? 'green'
                      : 'blue'
                  "
                >
                  {{ item.documents.complete }} / {{ item.documents.total }}
                </v-chip>
              </template>

              <template v-slot:[`item.references`]="{ item }">
                <ul v-if="item.references.length <= 5" class="ml-0 pl-0">
                  <li
                    v-for="(ref, index) in item.references"
                    :key="index"
                    style="font-size: 11px"
                  >
                    {{ ref }}
                  </li>
                </ul>
                <ul v-else class="ml-0 pl-0">
                  <li
                    v-for="(ref, index) in item.references.slice(0, 5)"
                    :key="index"
                    style="font-size: 11px"
                  >
                    {{ ref }}
                  </li>
                  <span style="font-size: 11px"
                    >+ {{ item.references.length - 5 }} Other(s)</span
                  >
                </ul>
              </template>

              <template v-slot:[`item.vessel`]="{ item }">
                <span>{{ item.vesselName }} {{ item.voyage }}</span>
              </template>
            </v-data-table>
          </v-col>
          <v-col cols="12" class="mt-0 pt-0 px-0" v-if="$vuetify.breakpoint.mobile">
            <v-expansion-panels v-for="(pos, index) in new Set(filteredResults.map(x => x.position))" :key="index">
            <v-expansion-panel>
              <v-expansion-panel-header class="py-0 pl-0 pr-7">
                <v-list-item dense class="pr-0">
                  <v-list-item-avatar class="mr-2">
                    <v-chip class="px-2" small >{{ filteredResults.filter(x => x.position == pos).length }}</v-chip>
                  </v-list-item-avatar>
                  <v-list-item-content>
                    <v-list-item-title>
                      <span style="font-size: 12px;">{{filteredResults.find(x => x.position == pos).vesselVoyageName}}</span>
                    </v-list-item-title>
                    <v-list-item-subtitle>
                      <v-chip class="px-2" x-small color="secondary">{{ filteredResults.filter(x => x.position == pos).map(x => x.noContainers).reduce((a, c ) => {return a + c}, 0) }}</v-chip>
                      <span style="font-size: 10px;" class="mr-2">Containers</span>
                      <v-chip class="px-2" x-small color="secondary">{{ filteredResults.filter(x => x.position == pos).map(x => x.noPallets).reduce((a, c ) => {return a + c}, 0) }}</v-chip>
                      <span style="font-size: 10px;" class="mr-2">Pallets</span>
                      <v-chip class="px-2" x-small color="secondary">
                        {{  filteredResults.filter(x => x.position == pos).map(x => x.documents).reduce((a, c ) => {return a + c.complete}, 0) + 
                            ' / ' + 
                            filteredResults.filter(x => x.position == pos).map(x => x.documents).reduce((a, c ) => {return a + c.total}, 0) }}</v-chip>
                      <span style="font-size: 10px;" class="mr-2">Documents</span>
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <div v-for="(item, idx) in filteredResults.filter(x => x.position == pos)" :key="idx">
                  <v-card outlined class="mb-1">
                    <v-toolbar dense flat>
                      <ul v-if="item.references.length <= 5" class="ml-0 pl-0">
                        <li
                          v-for="(ref, ix) in item.references"
                          :key="ix"
                          style="font-size: 11px"
                        >
                          {{ ref }}
                        </li>
                      </ul>
                      <ul v-else class="ml-0 pl-0">
                        <li
                          v-for="(ref, ix) in item.references.slice(0, 5)"
                          :key="ix"
                          style="font-size: 11px"
                        >
                          {{ ref }}
                        </li>
                        <span style="font-size: 11px"
                          >+ {{ item.references.length - 5 }} Other(s)</span
                        >
                      </ul>
                      <v-spacer></v-spacer>
                      <v-chip :color="getStatusColor(item.status)" outlined small>
                      <v-icon left small class="mr-1">{{
                        getStatusIcon(item.status)
                      }}</v-icon>
                      {{ item.status }}</v-chip
                    >
                    </v-toolbar>
                    <v-card-text>
                      <v-row>
                        <v-col
                            v-for="(header, indx) in shipmentHeaders.filter(x => x.value != 'status' && x.value != 'references')"
                            :key="indx"
                            class="pb-0"
                            cols="6"
                            sm="6"
                            lg="6"
                            style="word-wrap: normal"
                        >
                            <span style="display: block; font-size: small">
                                <b>{{ header.text }}</b>
                            </span>    
                            <div v-if="header.value == 'portOfLoadValue'">
                                <v-tooltip top v-if="item.portOfLoadValue">
                                  <template v-slot:activator="{ on }">
                                    <v-chip pill outlined v-on="on" small>
                                      <v-avatar size="24" left>
                                        <v-img
                                          contain
                                          :src="`https://cdn.loglive.io/flags/4x3/${item.portOfLoadValue
                                            .substring(0, 2)
                                            .toLowerCase()}.svg`"
                                        ></v-img>
                                      </v-avatar>
                                      {{ item.portOfLoadValue }}
                                    </v-chip>
                                  </template>
                                  <span style="font-size: 12px">{{ item.portOfLoadCity }}</span>
                                </v-tooltip>
                                </div>
                                <div v-else-if="header.value == 'finalDestinationValue'">
                                <v-tooltip top v-if="item.finalDestinationValue">
                                  <template v-slot:activator="{ on }">
                                    <v-chip pill outlined v-on="on" small>
                                      <v-avatar size="24" left>
                                        <v-img
                                          contain
                                          :src="`https://cdn.loglive.io/flags/4x3/${item.finalDestinationValue
                                            .substring(0, 2)
                                            .toLowerCase()}.svg`"
                                        ></v-img>
                                      </v-avatar>
                                      {{ item.finalDestinationValue }}
                                    </v-chip>
                                  </template>
                                  <span style="font-size: 12px">{{
                                    item.finalDestinationCity
                                  }}</span>
                                </v-tooltip>
                            </div>
                            <div v-else-if="header.value == 'products'">
                              <ul class="ml-0 pl-0">
                                <li
                                  v-for="product in item.products"
                                  :key="product.id"
                                  style="font-size: 11px"
                                >
                                  {{
                                    product.product
                                      ? $Format.capitalizeFirstLetter(product.product.name)
                                      : ""
                                  }}
                                </li>
                              </ul>
                            </div>
                            <div v-else-if="header.value == 'documents'">
                              <v-chip
                                v-if="
                                  item.documents.complete > 0 || item.documents.incomplete > 0
                                "
                                x-small
                                :color="
                                  item.documents.complete == item.documents.total
                                    ? 'green'
                                    : 'blue'
                                "
                              >
                                {{ item.documents.complete }} / {{ item.documents.total }}
                              </v-chip>
                            </div>
                            <div v-else-if="header.value == 'arrivalWeek'">
                              <span v-if="item.eta">{{ formatWeek(item.eta) }}</span>
                            </div>
                            <div v-else-if="header.value == 'shipper.name'">
                              <span v-if="item.shipper.name">{{ item.shipper.name }}</span>
                            </div>
                            <div v-else-if="header.value == 'consignee.name'">
                              <span v-if="item.consignee.name">{{ item.consignee.name }}</span>
                            </div>
                            <div v-else>
                              <span style="font-size: small">
                                {{ item[header.value] }}
                              </span>
                            </div>
                        </v-col>
                      </v-row>
                    </v-card-text>
                  </v-card>
                </div>
              </v-expansion-panel-content>
            </v-expansion-panel>

            </v-expansion-panels>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <div class="bottomBar" v-if="$vuetify.breakpoint.mobile">
      <v-btn small icon @click="mobileFilterModal = true"><v-icon>filter_alt</v-icon></v-btn>
      <v-chip
        style="height: 40px; width: 100%;"
        outlined
        small
        class="pr-1 text-center"
      >
        <v-icon small>search</v-icon>
        <v-text-field
          placeholder="Search"
          class="mb-1"
          hide-details
          rounded
          clearable
          dense
          v-model="search"
        ></v-text-field>
      </v-chip>
    </div>
    <v-bottom-sheet v-model="mobileFilterModal" >
      <v-sheet height="30vh" flat style="background-color: var(--v-greyBase-base) !important;">
        <v-toolbar flat>
          <v-toolbar-title>
            <v-icon class="mr-2" color="white">filter_alt</v-icon> Filter
          </v-toolbar-title>
          <v-spacer></v-spacer>
            <v-btn icon small color="redPop" @click="mobileFilterModal = false">
              <v-icon>close</v-icon>
            </v-btn>
        </v-toolbar>
        <v-card-text>
          <v-autocomplete
              dense
              class="mb-3"
              style="width: 100%; border-radius: 12px;"
              outlined
              hide-details
              :items="uniqueConsignees"
              v-model="filteredConsigneeId"
              item-text="name"
              item-value="id"
              label="Filtered Consignee"
              clearable
            >
              <template v-slot:label>
                <span style="color: hsla(0,0%,100%,.3); font-size: 14px;" class="ml-1">Filtered Consignee</span>
              </template>
              <template v-slot:prepend-inner>
                <v-icon small class="mt-1 mr-4">filter_alt</v-icon>
              </template>
          </v-autocomplete>
          <div style="display: flex; justify-content: center;">
            <v-menu
            ref="menu"
            v-model="dateMenu"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-chip
                color="blue-grey"
                v-bind="attrs"
                v-on="on"
                class="mb-3 mr-3"
                ><v-icon small left >date_range</v-icon>
                <span style="font-size: 10px;">{{ dateText }}</span>
                <v-icon  small right>expand_more</v-icon></v-chip
              >
            </template>
            <v-date-picker
              v-model="params.dates"
              range
              color="greyBase"
              show-week
              scrollable
            >
              <v-spacer></v-spacer>
              <v-btn text color="primary"> Cancel </v-btn>
              <v-btn text color="primary" @click="setDateFilter">
                OK
              </v-btn>
            </v-date-picker>
          </v-menu>
          <v-menu bottom offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-chip
                v-on="on"
                v-bind="attrs"
                color="blue-grey"
                class="mb-3 mr-2"
              >
              <span style="font-size: 10px;">
                {{ quickSelectDates.find(x => x.value == params.selectedDate).text }}
              </span>
                <v-icon small right>expand_more</v-icon>
              </v-chip>     
            </template>
            <v-card style="background-color: var(--v-greyBase-base) !important;">
              <v-list class="pa-0" dense >
                <v-list-item class="px-2" @click="selectDate(date.value)" v-for="(date, index) in quickSelectDates.filter(x => x.value != params.selectedDate)" :key="index">
                  <v-list-item-title>
                    {{ date.text }}
                  </v-list-item-title>
                </v-list-item>
              </v-list>
            </v-card>
          </v-menu>
        </div>
        <div style="display: flex; justify-content: center;">
          <v-chip
            :key="dateKey"
            :color="params.dateType == 'ETD' ? 'teal' : 'white'"
            outlined
            class="mr-3"
            @click="changeDateType()"
          >
            <v-icon small left>event</v-icon> 
            <span style="font-size: 10px;">Date Type:
            {{ params.dateType.toUpperCase() }}
            </span>
          </v-chip>
          <v-chip :outlined="!params.showAllFuture" :color="params.showAllFuture ? 'success' : 'white'" @click="params.showAllFuture = !params.showAllFuture, saveParams, getShipments()">
            <v-icon small left >{{ params.showAllFuture ? 'check_box' : 'check_box_outline_blank' }}</v-icon>
            <span style="font-size: 10px;">Show All Future Items</span>
          </v-chip>
        </div>
        </v-card-text>
      </v-sheet>
    </v-bottom-sheet>
    <v-dialog
      v-model="filterDialog"
      persistent
      width="500px"
      :fullscreen="$vuetify.breakpoint.mobile"
    >
      <v-card>
        <v-card-title>
          <v-icon class="mr-2" color="secondary">filter_alt</v-icon> Filter
          <v-spacer></v-spacer>
          <v-btn text @click="filterDialog = false">X</v-btn>
        </v-card-title>
        <v-card-text>
          <v-list dense>
            <v-subheader class="my-0 py-0" style="font-size: 16px">
              <v-icon color="secondary" class="mr-2" small>insights</v-icon
              >Status</v-subheader
            >
            <v-chip-group
              v-model="params.filter.vesselStatus"
              column
              multiple
              class="mt-0 pt-0"
            >
              <v-chip
                filter
                outlined
                value="Awaiting Departure"
                :color="
                  params.filter.vesselStatus &&
                  params.filter.vesselStatus.includes('Awaiting Departure')
                    ? 'orange'
                    : 'grey'
                "
              >
                Awaiting Departure
              </v-chip>
              <v-chip
                filter
                outlined
                value="In Transit"
                :color="
                  params.filter.vesselStatus &&
                  params.filter.vesselStatus.includes('In Transit')
                    ? 'blue'
                    : 'grey'
                "
              >
                In Transit
              </v-chip>
              <v-chip
                filter
                outlined
                value="Arrived"
                :color="
                  params.filter.vesselStatus &&
                  params.filter.vesselStatus.includes('Arrived')
                    ? 'success'
                    : 'grey'
                "
              >
                Arrived
              </v-chip>
            </v-chip-group>
            <v-subheader class="my-0 py-0" style="font-size: 16px">
              <v-icon color="secondary" class="mr-2" small>today</v-icon> Date
              Range
              <v-menu offset-y>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    color="primary"
                    dark
                    v-bind="attrs"
                    v-on="on"
                    text
                    icon
                  >
                    <v-icon>arrow_drop_down</v-icon>
                  </v-btn>
                </template>
                <v-list>
                  <v-list-item
                    v-for="(item, index) in quickDateFilters"
                    :key="index"
                    @click="setDateRange(item)"
                  >
                    <v-list-item-content>
                      <v-list-item-title>
                        {{ item.title }}
                      </v-list-item-title>
                      <v-list-item-subtitle>
                        {{ item.fromDate }} - {{ item.toDate }}
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
              </v-menu>
              <v-spacer></v-spacer>
              <v-btn
                color="teal"
                outlined
                small
                @click="
                  params.dateType == 'ETA'
                    ? (params.dateType = 'ETD')
                    : (params.dateType = 'ETA')
                "
                >{{ params.dateType == "ETA" ? "Arrival" : "Departure" }}</v-btn
              >
            </v-subheader>
            <v-list-item @click="startDateModal = true">
              <v-list-item-action>
                <v-icon color="grey">event_available</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title> Start Date </v-list-item-title>
              </v-list-item-content>
              <v-list-item-action-text :key="dateKey">
                {{ params.fromDate }}
              </v-list-item-action-text>
            </v-list-item>
            <v-list-item @click="endDateModal = true">
              <v-list-item-action>
                <v-icon color="grey">event_available</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title> End Date </v-list-item-title>
              </v-list-item-content>
              <v-list-item-action-text :key="dateKey">
                {{ params.toDate }}
              </v-list-item-action-text>
            </v-list-item>
            <v-subheader class="my-0 py-0" style="font-size: 16px">
              <v-icon small color="secondary" class="mr-2">groups</v-icon
              >Teams</v-subheader
            >
            <v-progress-linear
              v-if="loadingTeams"
              color="primary"
              indeterminate
            ></v-progress-linear>
            <v-chip-group
              v-model="params.teams"
              column
              multiple
              class="mt-0 pt-0"
              :key="teamKey"
            >
              <v-chip
                small
                v-for="team in allTeams"
                :key="team.id"
                filter
                outlined
                :value="team.id"
                @click="teamKey++"
                :color="params.teams.includes(team.id) ? team.colour : 'grey'"
              >
                {{ team.name }}
              </v-chip>
            </v-chip-group>
            <div
              v-if="!loadingTeams && allTeams.length == 0"
              style="width: 100%"
              class="text-center"
            >
              <p style="color: grey">No teams found</p>
            </div>
            <v-row justify="center" class="mt-3">
              <v-btn
                color="primary"
                text
                @click="getShipments(), (filterDialog = false)"
                >Apply Filters</v-btn
              >
            </v-row>
          </v-list>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog v-model="startDateModal" persistent width="290px">
      <v-date-picker
        v-if="params.fromDate"
        v-model="params.fromDate"
        scrollable
        :max="params.toDate"
      >
        <v-spacer></v-spacer>
        <v-btn text color="primary" @click="startDateModal = false"> OK </v-btn>
      </v-date-picker>
    </v-dialog>

    <v-dialog v-model="endDateModal" persistent width="290px">
      <v-date-picker
        v-if="params.toDate"
        v-model="params.toDate"
        scrollable
        :min="params.fromDate"
      >
        <v-spacer></v-spacer>
        <v-btn text color="primary" @click="endDateModal = false"> OK </v-btn>
      </v-date-picker>
    </v-dialog>

    <v-dialog v-model="infoStart" max-width="31vw">
      <v-card>
        <v-btn
          @click="infoStartGuide"
          icon
          style="position: absolute; right: 0; margin: 0.6vh 1vh 0 0"
          ><v-icon color="primary" size="30">close</v-icon></v-btn
        >
        <v-card-title class="d-flex justify-center">
          FRIKO Welcome to Shipments!
        </v-card-title>
        <v-card-text>
          <span>
            <p>FRIKO</p>
            <p>
              FRIKO <br />• Map out routes for transporters <br />• Add GeoJSON
              data to locations used determine if a vehicle is within a certain
              area <br />• Calculate accurate waiting times at loading points
              <br />• Calculate distances for billing purposes <br />• Pin-point
              locations for the purpose of reporting and analysis <br />• Map
              out farms that aren't found on Google Maps
            </p>
            If you would like learn how to load POI's into the system, click the
            button below to start the tutorial.
          </span>
        </v-card-text>
        <v-card-actions class="d-flex justify-center">
          <v-btn @click="startGuide" color="edit" class="mb-3">Start!</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
    
  <script>
import dateFormat from "dateformat";
import * as moment from "moment";

import Driver from "driver.js";
import "driver.js/dist/driver.min.css";
// import steps from "./Steps/shipmentsSteps";

export default {
  components: {},
  data: () => ({
    steps: null,
    allTeams: [],
    badgeKey: 5000,
    dateMenu: false,
    myTeams: [],
    mobileFilterModal: false,
    teamKey: 3000,
    filterKey: 1000,
    endDateModal: false,
    startDateModal: false,
    filteredConsigneeId: null,
    filterSet: false,
    filterDialog: false,
    shipmentHeaders: [
      {
        text: "Status",
        value: "status",
        align: "center",
      },
      {
        text: "Arrival Week",
        value: "arrivalWeek",
        width: "20px",
        align: "center",
      },
      {
        text: "Carrier",
        value: "carrier",
        sortable: false,
        align: "center",
      },
      // {
      //     text: "ETA/ATA",
      //     value: "eta",
      //     width: '120px',
      //     sortable: false,
      //     align: "center",
      // },

      {
        text: "Shipper",
        value: "shipper.name",
        sortable: false,
        width: "180px",
        align: "left",
      },
      {
        text: "Receiver",
        value: "consignee.name",
        sortable: false,
        width: "180px",
        align: "left",
      },
      // {
      //   text: "Vessel/Voyage",
      //   value: "vessel.name",
      //   sortable: false,
      //   align: "center",
      // },
      {
        text: "POL",
        value: "portOfLoadValue",
        sortable: false,
        align: "left",
      },
      {
        text: "Final Dest.",
        value: "finalDestinationValue",
        sortable: false,
        align: "left",
      },
      // {
      //     text: "ETD/ATD",
      //     value: "etd",
      //     sortable: false,
      //     align: "center",
      // },

      {
        text: "Products",
        value: "products",
        align: "left",
        sortable: false,
      },
      {
        text: "References",
        value: "references",
        align: "left",
        sortable: false,
      },
      {
        text: "Containers",
        value: "noContainers",
        align: "center",
      },
      {
        text: "No. Pallets",
        value: "noPallets",
        align: "center",
      },
      {
        text: "Units",
        value: "noCartons",
        align: "center"
      },
      {
        text: "Documents",
        value: "documents",
        align: "center",
      },
    ],
    dateKey: 2000,
    consigneeGroup: [
      "consigneeId",
      "vesselId",
      "voyage",
      "portOfLoadValue",
      "finalDestinationValue",
      "arrivalWeek",
    ],
    shipperGroup: [
      "shipperId",
      "consigneeId",
      "vesselId",
      "voyage",
      "portOfLoadValue",
      "finalDestinationValue",
      "arrivalWeek",
    ],
    initialLoadComplete: false,
    loading: false,
    loadingFiles: false,
    loadingDocuments: false,
    loadingTeams: false,
    page: 1,
    params: {
      limit: 15,
      offset: 0,
      search: null,
      dateType: "eta",
      fromWeek: moment(new Date()).isoWeek() - 4,
      toWeek: moment(new Date()).isoWeek() + 8,
      filter: {
        vesselStatus: ["Awaiting Departure", "In Transit"],
      },
    },
    quickSelectDates: [
      // {
      //     text: 'Past 30 days',
      //     type: 'past',
      //     value: 'past_month',
      //     difference: -1
      // },
      {
        text: "Past 90 Days",
        type: "past",
        value: "past_90_days",
        difference: -90,
      },
      {
        text: "Past 60 Days",
        type: "past",
        value: "past_60_days",
        difference: -60,
      },
      {
        text: "Past 30 Days",
        type: "past",
        value: "past_30_days",
        difference: -30,
      },
      // // {
      // //     text: 'Last 3 Days',
      // //     value: 'past_3day',
      // //     type: 'past',
      // //     difference: -3
      // // },
      // {
      //     text: 'Current 3 Days',
      //     type: 'current',
      //     value: 'current_3day',
      //     difference: 0
      // },

      {
        text: "This week",
        type: "current",
        value: "current_week",
        difference: 0,
      },
      {
        text: "Next 2 weeks",
        type: "future",
        value: "2_week",
        difference: 0,
      },
      {
        text: "Next 6 weeks",
        type: "future",
        value: "6_week",
        difference: 0,
      },
    ],
    shipments: {
      total: 0,
      data: [],
    },
    shipmentTimeout: null,
    tab: 0,
    updateBadge: 0,

    // Filters
    search: null,
    searchShippers: null,
    searchingShippers: false,
    shippers: [],
    shipperTimer: null,
    shipmentExistingQuery: undefined,

    searchReceivers: null,
    searchingReceivers: false,
    selectedType: "Shipper",
    receivers: [],
    receiverTimer: null,

    searchVessels: null,
    searchingVessels: false,
    vessels: [],
    vesselTime: null,

    searchVoyage: null,
    searchingVoyage: false,
    voyages: [],
    voyageTimer: null,
    infoStart: false,
  }),
  watch: {
    "$route.query.type": {
      immediate: true,
      handler(val) {
        if (val) this.docParams.filter.shippingDocumentId = parseInt(val);
      },
    },
    "$route.query.status": {
      immediate: true,
      handler(val) {
        if (val) this.docParams.filter.status = val;
      },
    },
    // "search": {
    //     immediate: true,
    //     async handler() {
    //         this.params = {
    //             ...this.params,
    //             offset: 0,
    //         };
    //         // this.getShipments()
    //     },
    // },
    async page(value) {
      this.params.offset = (value - 1) * this.params.limit;
      this.getShipments();
    },
    "params.limit": {
      immediate: true,
      async handler() {
        this.getShipments();
      },
    },
    searchReceivers(val) {
      if (this.receiverTimer) {
        clearTimeout(this.receiverTimer);
      }
      this.searchingReceivers = true;
      this.receiverTimer = setTimeout(async () => {
        this.receivers = await this.$API.getShipmentFilterDetails({
          search: val,
          field: "receiverName",
        });
        this.searchingReceivers = false;
      }, 500);
    },
    searchShippers(val) {
      if (this.shipperTimer) {
        clearTimeout(this.shipperTimer);
      }
      this.searchingShippers = true;
      this.shipperTimer = setTimeout(async () => {
        this.shippers = await this.$API.getShipmentFilterDetails({
          search: val,
          field: "shipperName",
        });
        console.log(this.shippers);
        this.searchingShippers = false;
      }, 500);
    },
    searchVessels(val) {
      if (this.vesselTimer) {
        clearTimeout(this.vesselTimer);
      }
      this.searchingVessels = true;
      this.vesselTimer = setTimeout(async () => {
        this.vessels = await this.$API.getShipmentFilterDetails({
          search: val,
          field: "vesselName",
        });
        this.searchingVessels = false;
      }, 500);
    },
    searchVoyage(val) {
      if (this.voyageTimer) {
        clearTimeout(this.voyageTimer);
      }
      this.searchingVoyage = true;
      this.voyageTimer = setTimeout(async () => {
        this.voyages = await this.$API.getShipmentFilterDetails({
          search: val,
          field: "voyage",
          filterField: "vesselName",
          filterValue: this.params.filter.vesselName,
        });
        this.searchingVoyage = false;
      }, 500);
    },
  },
  computed: {
    computeFilterCount() {
      let count = 0;
      if (this.params.fromDate) {
        count++;
      }
      if (this.params.toDate) {
        count++;
      }
      if (this.params.filter) {
        let filterKeys = Object.keys(this.params.filter);
        for (let i = 0; i < filterKeys.length; i++) {
          if (
            this.params.filter[filterKeys[i]] &&
            this.params.filter[filterKeys[i]].length > 0
          ) {
            count++;
          }
        }
      }

      if (this.params.teams && this.params.teams.length > 0) {
        count++;
      }
      return count;
    },
    dateText() {
      if (this.params.dates.length === 0) {
        return "Select Dates";
      } else if (this.params.dates.length === 1) {
        return "Week: " + moment(this.params.dates[0]).isoWeek();
      } else {
        return `${this.params.dates[0]} - ${this.params.dates[1]}`;
      }
    },
    getCurrentWeek() {
      let today = new Date();
      let date = moment(today);
      return date.isoWeek();
    },
    filterProfiles() {
      let result = this.profiles.data;
      if (this.shipment && this.shipment.pod) {
        result = result.filter(
          (x) =>
            x.consigneeProfilePorts.filter((y) => y.code == this.shipment.pod)
              .length > 0
        );
      }
      if (result.length == 0) {
        result = this.profiles.data;
      }
      return result;
    },
    filteredResults() {
      let result = this.shipments.data ?? [];
      if (this.search) {
        result = result.filter(
          (x) =>
            x.references.some((y) =>
              y.toLowerCase().includes(this.search.toLowerCase())
            ) ||
            (x.shipper &&
              x.shipper.name
                .toLowerCase()
                .includes(this.search.toLowerCase())) ||
            (x.consignee &&
              x.consignee.name
                .toLowerCase()
                .includes(this.search.toLowerCase())) ||
            // x.vessel.name.toLowerCase().includes(this.search.toLowerCase()) ||
            // x.voyage.toLowerCase().includes(this.search.toLowerCase()) ||
            // x.products.some((y) =>
            //   y.product.name.toLowerCase().includes(this.search.toLowerCase())
            // )
            (x.vesselVoyageName &&
              x.vesselVoyageName
                .toLowerCase()
                .includes(this.search.toLowerCase()))
        );
      }
      if (this.filteredConsigneeId) {
        result = result.filter(
          (x) => x.consigneeId == this.filteredConsigneeId
        );
      }
      return result;
    },

    quickDateFilters() {
      let result = [
        {
          title: "Current 6 week span",
          fromDate: moment(new Date())
            .subtract(2, "weeks")
            .startOf("isoWeek")
            .format("YYYY-MM-DD"),
          toDate: moment(new Date())
            .add(4, "weeks")
            .endOf("isoWeek")
            .format("YYYY-MM-DD"),
        },
        {
          title: "This Week",
          fromDate: moment(new Date()).startOf("isoWeek").format("YYYY-MM-DD"),
          toDate: moment(new Date()).endOf("isoWeek").format("YYYY-MM-DD"),
        },
        {
          title: "Last 6 weeks",
          fromDate: moment(new Date())
            .subtract(6, "weeks")
            .startOf("isoWeek")
            .format("YYYY-MM-DD"),
          toDate: moment(new Date()).endOf("isoWeek").format("YYYY-MM-DD"),
        },
        {
          title: "Last 12 weeks",
          fromDate: moment(new Date())
            .subtract(12, "weeks")
            .startOf("isoWeek")
            .format("YYYY-MM-DD"),
          toDate: moment(new Date()).endOf("isoWeek").format("YYYY-MM-DD"),
        },
      ];
      return result;
    },
    uniqueConsignees() {
      let result = [];
      let consignees = [
        ...new Set(this.shipments.data.map((x) => x.consigneeId)),
      ].filter(Boolean);
      for (let i = 0; i < consignees.length; i++) {
        let consignee = this.shipments.data.find(
          (x) => x.consigneeId == consignees[i]
        );
        result.push({
          id: consignees[i],
          name: consignee.consignee.alias ?? consignee.consignee.name,
        });
      }
      result.sort((a, b) => (a.name > b.name ? 1 : -1));
      return result;
    },
    weeks() {
      let result = [];
      for (let i = 1; i <= 52; i++) {
        result.push(i);
      }
      return result;
    },
  },
  async mounted() {
    this.driver = new Driver({
      animate: false,
    });
    // this.filterDialog = false
    let storedFilter = await sessionStorage.getItem(
      'shipmentOverviewParams_'+this.$store.state.currentOrg.id
    );
    //   console.log(storedFilter);
    if (storedFilter) {
      // console.log(storedFilter)
      this.params = JSON.parse(storedFilter);
      if (!this.params.teams) {
        this.params.teams = [];
      }
      if (!this.params.fromDate || !this.params.toDate) {
        this.setDateRange(this.quickDateFilters[0]);
      }
      this.filterSet = true;

      this.getShipments();
    } else {
      if (!this.params.teams) {
        this.params.teams = [];
      }
      this.setDateRange(this.quickDateFilters[0]);
    }
    this.getTeams();
  },
  async created() {
    if (localStorage['shipmentOverviewParams_'+this.$store.state.currentOrg.id]) {
      this.params = JSON.parse(localStorage['shipmentOverviewParams_'+this.$store.state.currentOrg.id]);
      if (!this.params.dateType) {
        this.params.dateType = "ETD";
      }
    }
    if (!this.params) {
      this.params = {
        dateType: "ETD",
        dates: [],
      };
      this.selectDate("current_week");
    }

    if(!this.params.selectedDate && (!this.params.dates || this.params.dates.length == 0)){
      this.selectDate("current_week");
    }
    // if (!this.params.selectedDate) {
    //   this.selectDate("current_week");
    // } else {
    //   this.selectDate(this.params.selectedDate);
    // }
    console.log(this.params)
    await this.getSteps();
  },
  methods: {
    async getSteps() {
        let steps = await this.$API.getGuideSteps({
            guideId: 'ShipmentOverview/Shipments/Steps',
        });
        this.steps = steps.steps
    },
    activeFilters() {
      let keys = Object.keys(this.params.filter);
      let count = 0;
      for (let i = 0; i < keys.length; i++) {
        if (this.params.filter[keys[i]]) {
          count++;
        }
      }
      return count;
    },
    calculateContainers(bookings) {
      return bookings
        .map((item) => item.bookingContainers.length)
        .reduce((prev, next) => prev + next);
    },
    async addShipment(item) {
      this.shipment = {
        shipmentFileId: item.id,
        consigneeProfileId: null,
      };
      this.shipmentDialog = true;
    },
    changeDateType() {
      if (this.params.dateType == "ETD") {
        this.params.dateType = "ETA";
      } else {
        this.params.dateType = "ETD";
      }
      this.dateKey++;
      this.getShipments();
    },
    count(type) {
      let filter = this.shipments.data.filter((x) => x.status == type);
      return filter.length;
    },
    formatDate(date) {
      let result = null;
      if (date) {
        result = dateFormat(new Date(date), "dd mmm, yyyy HH:MM");
      }
      return result;
    },
    filteredContainers(status) {
      let data = this.filteredResults.filter((x) => x.status === status);
      return data.map((x) => x.noContainers).reduce((a, b) => a + b, 0);
    },
    filteredPallets(status) {
      let data = this.filteredResults.filter((x) => x.status === status);
      return data.map((x) => x.noPallets).reduce((a, b) => a + b, 0);
    },
    formatWeek(date) {
      return (
        moment(new Date(date)).isoWeek() +
        " - " +
        moment(new Date(date)).format("YYYY")
      );
    },
    getStatusColor(status) {
      switch (status) {
        case "Awaiting Departure":
          return "orange";
        case "In Transit":
          return "blue";
        case "Arrived":
          return "green";
        case "Cancelled":
          return "red";
        default:
          return "darkgrey";
      }
    },
    getStatusIcon(status) {
      switch (status) {
        case "Awaiting Departure":
          return "location_on";
        case "In Transit":
          return "mode_of_travel";
        case "Arrived":
          return "where_to_vote";
        case "Cancelled":
          return "close";
        default:
          return "info";
      }
    },
    getItemStatusColor(item) {
      let today = dateFormat(new Date(), "yyyy-mm-dd");
      let status = null;
      if (item.submittedCount < item.requiredCount) {
        if (new Date(item.pendingDue) < new Date(today)) {
          status = "Overdue";
        } else if (item.pendingDue == today) {
          status = "Due Today";
        } else {
          status = "Pending";
        }
      } else {
        status = "Complete";
      }
      switch (status) {
        case "Due Today":
          return "orange";
        case "Overdue":
          return "red";
        case "Pending":
          return "blue";
        case "Complete":
          return "green";
        default:
          return "darkgrey";
      }
    },
    async getShipments() {
      // this.params.id = 'test_1'
      await sessionStorage.removeItem(
        'shipmentOverviewParams_'+this.$store.state.currentOrg.id
      );
      await sessionStorage.setItem(
        'shipmentOverviewParams_'+this.$store.state.currentOrg.id,
        JSON.stringify(this.params)
      );
      this.badgeKey++;
      if (this.shipmentTimeout) {
        clearTimeout(this.shipmentTimeout);
      }
      if (this.loading && this.shipmentExistingQuery) {
        this.shipmentExistingQuery.abort();
      }
      this.loading = true;
      if (this.params.filter.breakBulkShipment != true) {
        delete this.params.filter.breakBulkShipment;
      }
      if (this.selectedType == "Shipper") {
        this.params.group = this.shipperGroup;
      } else {
        this.params.group = this.consigneeGroup;
      }
      this.shipmentTimeout = setTimeout(async () => {
        this.shipmentExistingQuery = new AbortController();
        const signal = this.shipmentExistingQuery.signal;
        this.shipments = await this.$API.getOverviewShipments({
          params: this.params,
          signal,
        });
        this.loading = false;
        if(!this.$vuetify.breakpoint.mobile) {
          setTimeout(() => {
            Object.keys(this.$refs.table.openCache).forEach(
              (g) => (this.$refs.table.openCache[g] = false)
              );
            }, 500);
            }
        }, 750);
      },
    async getTeams() {
      this.loadingTeams = true;
      this.params.teams = [];
      this.allTeams = await this.$API.getTeams();
      this.myTeams = await this.$API.getUserTeams();
      this.params.teams = this.myTeams.map((x) => x.teamId);
      this.loadingTeams = false;
    },
    viewShipment(item) {
      this.$router.push({
        path: "/shipment/overview/" + item.hash,
        query: {
          status: item.status,
          vesselId: item.vesselId,
          vessel: item.vessel.name,
          voyage: item.voyage,
          vesselVoyageId: item.vesselVoyageId,
          shipper: item.shipper.name,
          shipperId: item.shipperId,
          consignee: item.consignee.name,
          consigneeId: item.consigneeId,
          pol: item.portOfLoadValue,
          pod: item.finalDestinationValue,
          arrivalWeek: item.arrivalWeek,
          etd: item.etd,
          eta: item.eta,
          noContainers: item.noContainers,
          noPallets: item.noPallets,
          noCartons: item.noCartons
        },
      });
    },
    infoStartGuide() {
      if (this.infoStart) {
        this.infoStart = false;
      } else {
        this.infoStart = true;
      }
    },
    selectDate(value) {
      this.params.selectedDate = value;
      this.setDateRange(value);
      console.log("Setting params", JSON.stringify(this.params));
      localStorage.setItem(
        'shipmentOverviewParams_'+this.$store.state.currentOrg.id,
        JSON.stringify(this.params)
      );
    },
    saveParams() {
      localStorage.setItem(
        'shipmentOverviewParams_'+this.$store.state.currentOrg.id,
        JSON.stringify(this.params)
      );
    },
    setDateFilter() {
      if (this.params.dates.length == 2) {
        if (this.params.dates[1] < this.params.dates[0]) {
          let dates = [this.params.dates[1], this.params.dates[0]];
          this.params.dates = dates;
        }
      }
      localStorage.setItem(
        'shipmentOverviewParams_'+this.$store.state.currentOrg.id,
        JSON.stringify(this.params)
      );
      this.getShipments();
      this.dateMenu = false;
    },
    setDateRange(val) {
      switch (val) {
        case "current_week":
          this.params.dates = [
            new Date(
              new Date().setDate(new Date().getDate() - new Date().getDay() + 1)
            )
              .toISOString()
              .substring(0, 10),
            new Date(
              new Date().setDate(new Date().getDate() - new Date().getDay() + 7)
            )
              .toISOString()
              .substring(0, 10),
          ];
          break;
        case "2_week":
          this.params.dates = [
            new Date(new Date().setDate(new Date().getDate() + 1))
              .toISOString()
              .substring(0, 10),
            new Date(new Date().setDate(new Date().getDate() + 14))
              .toISOString()
              .substring(0, 10),
          ];
          break;
        case "6_week":
          this.params.dates = [
            new Date(new Date().setDate(new Date().getDate() + 1))
              .toISOString()
              .substring(0, 10),
            new Date(new Date().setDate(new Date().getDate() + 42))
              .toISOString()
              .substring(0, 10),
          ];
          break;
        case "current_60_days":
          this.params.dates = [
            new Date(new Date().setDate(new Date().getDate() - 30))
              .toISOString()
              .substring(0, 10),
            new Date(new Date().setDate(new Date().getDate() + 30))
              .toISOString()
              .substring(0, 10),
          ];
          break;
        case "past_30_days":
          this.params.dates = [
            new Date(new Date().setDate(new Date().getDate() - 30))
              .toISOString()
              .substring(0, 10),
            new Date(new Date().setDate(new Date().getDate()))
              .toISOString()
              .substring(0, 10),
          ];
          break;
        case "past_60_days":
          this.params.dates = [
            new Date(new Date().setDate(new Date().getDate() - 60))
              .toISOString()
              .substring(0, 10),
            new Date(new Date().setDate(new Date().getDate()))
              .toISOString()
              .substring(0, 10),
          ];
          break;
        case "past_90_days":
          this.params.dates = [
            new Date(new Date().setDate(new Date().getDate() - 90))
              .toISOString()
              .substring(0, 10),
            new Date(new Date().setDate(new Date().getDate()))
              .toISOString()
              .substring(0, 10),
          ];
          break;
      }
      this.getShipments();
    },
    startGuide(e) {
      this.infoStart = false;
      e.stopPropagation();
      this.driver.defineSteps(this.steps);
      this.driver.start();
    },
  },
};
</script>
    
<style>

.bottomBar {
    z-index: 2;
    height: 3rem;
    width: 100dvw;
    position: fixed;
    display: flex;
    align-items: center;
    padding: 0.2rem 0.5rem;
    bottom: 0;
    background-color: var(--v-greyRaised-base) !important;
}

.fontSomething {
  color: blue-gray;
}
</style>